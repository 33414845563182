<script setup lang="ts">
import MoonIcon from '@heroicons/vue/24/solid/esm/MoonIcon'
import SunIcon from '@heroicons/vue/24/solid/esm/SunIcon'

const colorMode = useColorMode()

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  },
})
</script>

<template>
  <ClientOnly>
    <button
      class="focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-sm gap-x-1.5 p-1.5 text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 inline-flex items-center"
      aria-label="Theme"
      type="button"
      @click="isDark = !isDark"
    >
      <MoonIcon v-if="isDark" class="flex-shrink-0 w-5 h-5" />
      <SunIcon v-else class="flex-shrink-0 w-5 h-5" />
    </button>

    <template #fallback>
      <div class="w-8 h-8" />
    </template>
  </ClientOnly>
</template>
